<template>
  <div class="tarih">
    <div @click="openModal()">
      <span><img class="tarih-svg" src="@/assets/svg/Clock.svg" alt="" /></span>
      <span class="tarih-yazi">{{ $t("Nearest delivery") }}:</span>
      <span
        class="tarih-saat"
        v-for="randevu in enyakinrandevutarihi.randevu"
        :key="randevu.id"
      >
        <span>{{ randevu.enyakinrandevu }}</span>
      </span>
    </div>
    <!-- <div v-if="false">
      <div class="arkafon" @click="closeModal">
        <transition name="slide-fade">
          <div v-if="true" class="ownModal" @click="modalAcikTut">
            <div class="baslik">
              <h4 class="d-flex justify-content-center">
                Adresinizi Seçiniz
                <button
                  @click="closeModal"
                  type="button"
                  class="kapatbuton"
                  aria-label="Close"
                >
                  <span aria-hidden="true" class="black-text">&times;</span>
                </button>
              </h4>
            </div>
            <div class="icerik">dwdadw</div>
          </div>
        </transition>
      </div>
    </div> -->
    <div v-if="modalContent">
      <div class="modaloverlay" @click="closeModal"></div>
      <div class="modalcontent d-flex row justify-content-end" >
        <button
          @click="closeModal"
          type="button"
          class="kapatbuton"
          aria-label="Close"
        >
          <span aria-hidden="true" class="black-text">&times;</span>
        </button>
        <div
          class="modaldata"
          style="width: 100%"
          v-for="h in enyakinrandevutarihi.randevu"
          :key="h.id"
        >
          <div
            v-for="i in h.randevu_bilgi"
            :key="i"
            class="mt-2"
            style="
              border: 1px solid red;
              border-radius: 10px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            "
          >
            <div
              style="
                white-space: nowrap;
                font-weight: bold;
                padding: 4px;
                padding-left: 10px;
                color: #02C606;
                border-bottom: 0px solid #ddd;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
              "
            >
              {{ i.tarih }} {{ i.gun }}
            </div>

            <div
              style="
              padding: 4px;
              padding-left: 10px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

                margin-top: 10px;
              "
            >
            <span :class="j.status==='false'? 'linethrough':'' " v-for="j in i.saatler" :key="j"> {{ j.ad }}</span>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      randevuBilgi: [],
      errors: [],
      // cookie bilgileri START
      utel: "",
      uid: "",
      // cookie bilgileri END
      koordinat: "",
      modalContent: "",
    };
  },
  computed: {
    ...mapGetters(["serverRequest"]),
    enyakinrandevutarihi: {
      // bu mapgetters ile ayni islevi yapmaktadir. fonksiyona bakildiginda anlasilacagi uzere
      get() {
        return this.$store.getters.enYakinRandevuTarihi;
      },
    },
  },
  methods: {
    async fetchRandevuBilgi() {
      this.getKoordinat();
      this.getUid_Udep();
      try {
        const formData = new FormData();
        formData.append("f", "web_get_init_data");
        formData.append("depo", this.koordinat);
        formData.append("os", "web");
        formData.append("version", "1");
        formData.append("fbid", "0");
        formData.append("teslimattip", "1");
        var getUserInLocal = localStorage.getItem("user");
        if (getUserInLocal != null) {
          // kullanici kaydi varsa. kontrol localdeki 'user'-e gore yapiliyor
          formData.append("user", this.utel);
          formData.append("uid", this.uid);
        }
        const response = await axios.post(this.serverRequest, formData, {
          headers: {
            // 'Accept': 'application/json',
            // "Access-Control-Allow-Origin": "*",
            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        });
        this.randevuBilgi = response.data;
        // others.js-deki addEnYakinRandevuTarihi fonksiyonu calisir ve degerler enyakinrandevutarihi-ne yazilir
        this.$store.commit("addEnYakinRandevuTarihi", {
          randevu: this.randevuBilgi,
        });
        // console.log("sorgu yapildi")
      } catch (error) {
        this.errors.push(error); // hata mesajlari data() icerisinde de tanimlanan error array-ine yazilir
      }
    },
    getKoordinat() {
      this.koordinat = localStorage.getItem("koord");
    },
    getUid_Udep() {
      this.utel = this.decryptCookie("utel");
      this.uid = this.decryptCookie("uid");
    },
    openModal() {
      this.modalContent = "show";
    },
    closeModal() {
      this.modalContent = "";
    },
  },
  created() {
    // bu kontrol her defasinda sorgu yapilmasin diye eklendi. fetchRandevuBilgi fonksiyonu icinde store-daki enyakinrandevutarihi icine
    // sorgu sonrasi donen response yaziliyor. eger bu degerin ici doluysa sorgu yapilmistir demektir ve bir daha sorgu yapilmiyor
    if (this.enyakinrandevutarihi === "") {
      this.fetchRandevuBilgi();
    }
  },
};
</script>

<style scoped>
[v-cloak] > * {
  display: none;
}
@media (max-width: 490px) {
  .tarih {
    width: 80% !important;
    margin-left: 60px !important;
  }
}
@media (max-width: 390px) {
  .tarih-yazi {
    display: none !important;
  }
  .tarih {
    width: 88% !important;
    margin-left: 20px !important;
  }
}
.tarih {
  border: 1px solid white;
  border-radius: 8px;
  background: white;
  color: #EB851E;
  font-weight: 800;
  padding: 5px;
  margin-left: 0px;
}
.tarih-svg {
  width: 16px;
  margin: -2px 5px 0px 3px;
}
.tarih-yazi {
  color: #5f5f5f;
  text-transform: capitalize;
}
.tarih-saat span {
  position: relative;
  font-size: 13px;
  text-decoration: underline;
  margin-left: 1px;
}

.linethrough{
  color: rgb(170, 170, 170);
  text-decoration: line-through;
}
.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1999;
}

.modalcontent {
  position: fixed;
  width: 50%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 2000;
  border-radius: 10px;
}

@media only screen and (max-width: 1268px) {
  .modalcontent {
    padding: 8px;
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .modalcontent {
    padding: 4px;
    width: 90%;
  }
}

.modaldata {
  background: none;
}

.closebutton {
  margin-bottom: 10px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.arkafon {
  z-index: 1000;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: calc(50% - 50vw);
  width: 100vw;
  height: 100%;
  background: rgba(43, 43, 43, 0.8);
  display: flex;
  justify-content: center;
  overflow-x: auto;
}
.ownModal {
  z-index: 100;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  background: white;
  max-width: 500px;
  min-width: 250px;
  position: absolute;
  top: 20px;
  padding: 30px;
}

.baslik {
  padding-bottom: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
}
.baslik span {
  padding-top: 10px;
  font-weight: 400;
}
.kapatbuton {
  background: none;
  border: none;
  right: 10px;
  margin-bottom: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
